.teste {
  width: 130px;
  height: 130px;
  padding-left: 5px;
  padding-bottom: 10px;
  text-align: center;
  display: block;
  border-radius: 25px !important;
}

.contaBancaria {
  padding-left: 5px;
  padding-bottom: 10px;
  display: block;
  size: 14;
  font-weight: bold;
}

.letraMarcada {
  color: rgb(218, 16, 16);
}


.letraNaoMarcada {
  color: #348BFF;
}

.space-right{
  padding-right: 1.25rem;
}