.cardKleiton {
  background-color: transparent;
  border-bottom: 1px solid rgba(28, 183, 197, 0.795);
  padding: .75rem 1.25rem;
  position: relative;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.padding-sub-item{
    padding-left: 1rem;
}

.card-primary{
    border-top: 0px !important;
    padding-top: 0px !important;
}
.secondary-button{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: .6rem;
    position:absolute;
    z-index: 1097;
    margin-left: 200px;
    align-content: flex-end;
    max-width: 40px;
    color: rgba(0, 0, 0, 0.5);
}

.width-control{
    width: 19rem !important;
}

.p-badge-lg{
    color: black;
}
.row-etrium{
    display: flex;
    flex-direction: row;
}

.col-etrium{
    display: flex;
    flex-direction: column;
}

.teste {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem;
}

.containerTeste {
  display: none;
}

.modal-content2 {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 50%);
    outline: 0;
    margin-top: 50%;
}

html,
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.2;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

body input{
    height: 37px !important;
}

.bodyBar {
  font-size: 1rem !important;
  border-width: 2px !important;
  border-top: #3B82F6 !important;
}

.bodyBar2 {
  font-size: 1.3rem !important;
}

.progress{
  margin-bottom:1px;
}

.content-wrapper{
  min-height: 100% !important;
}

.content-header {
  padding: 15px 0.5rem;
  background-color: #fff;
}

body:not(.layout-fixed) .main-sidebar {
  min-height: calc(110vh - calc(3.5rem + 1px) - calc(3.5rem + 1px));
  position: absolute;
  top: 0;

}

.main-sidebar {
  min-height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px));
  overflow-y: hidden;
  z-index: 1038;
    background-color: #fff !important;
}

.border-blue{
    border-bottom: 3px solid #007bff;
}

.no-effect:hover{
    color:#007bff !important;
}

.sidebar {
  /*min-height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px));*/
  /*overflow-x: ;*/
  overflow-y: initial;
  padding: 0;
  padding-bottom: 0;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: 0;
    background-color: #fff !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.elevation-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)!important;
  min-height: 100%;
}

.cursor {
  cursor: pointer;
}

.left {
  text-align: left;
}

.brand-link{
    justify-content: center;
    align-content: center;
    font-size: 1em;
    border-bottom: 3px solid  #3B82F6;
}

.ml-negative{
    margin-left: -41px;
}

.expand-div {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  border-top: 2px solid #bdbdbd86;
}

.expand-div.expanded {
  max-height: 100px;
  opacity: 1;
  margin-top: 10px;
}
