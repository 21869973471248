.card {
  padding: 10px;
}

.titulo {
  padding-bottom: 10px;
  font-size: 12px !important;
  font-weight: bold;
}

.usuario {
  color: rgb(105, 77, 77);
  font-style: italic;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}

.dataFatal {
  color: red;
  font-weight: bold;
}


.status-1 {
  padding-top: 10px;
  color:#b8860b;
  font-size: 14px;
  font-weight: bold;
}
.status-2 {
  padding-top: 10px;
  color: #b4b4aae3;
  font-size: 14px;
  font-weight: bold;
}
.status-3 {
  padding-top: 10px;
  color: #00ffff;
  font-size: 14px;
  font-weight: bold;
}
.status-4 {
  padding-top: 10px;
  color: #1229ab;
  font-size: 14px;
  font-weight: bold;
}

.status-4 {
  padding-top: 10px;
  color: #1229ab;
  font-size: 14px;
  font-weight: bold;
}
.status-5 {
  padding-top: 10px;
  color: #4e8814;
  font-size: 14px;
  font-weight: bold;
}
.status-6 {
  padding-top: 10px;
  color: #ff0000;
  font-size: 14px;
  font-weight: bold;
}

.status-aguardando-inicio {
  padding-top: 10px;
  color: #b4b4aae3;
  font-size: 14px;
  font-weight: bold;
}


.status-suspenso {
  padding-top: 10px;
  color: #00ffff;
  font-size: 14px;
  font-weight: bold;
}

.status-cancelado {
  padding-top: 10px;
  color: #ff0000;
  font-size: 14px;
  font-weight: bold;
}

.status-concluido {
  padding-top: 10px;
  color: #4e8814;
  font-size: 14px;
  font-weight: bold;
}

.status-em-atendimento {
  padding-top: 10px;
  color: #1229ab;
  font-size: 14px;
  font-weight: bold;
}

.linkCadGrupo {
  font-size: 10px;
  padding-top: 5px;
  color: blue;
}

.linkCadGrupoHistorico {
  font-size: 10px;
  padding-top: 15px;
}

.anexo {
  padding-bottom: 12px;
}

.cursor {
  cursor: pointer;
}

.grupoTrabalho {
  font-size: 12px;
  font-weight: bold;
}

.exibirLinkArquivos {
  display: block;
}

.naoExibirLinkArquivos {
  display: none;
}

.subMenu {
  background-color: white;
  opacity: 0.3;
}

.folgado {
  border-radius: 5px;
  padding-top: 3px;
  background-color: #4FFF00;
  display: inline;
  padding: 8px;
}

.leve {
  border-radius: 5px;
  padding-top: 3px;
  background-color: #AAFF00;
  display: inline;
  padding: 8px;
}

.apertado {
  border-radius: 5px;
  background-color: #F8AA03;
  display: inline;
  padding: 8px;
}

.critico {
  border-radius: 5px;
  background-color: #F40103;
  display: inline;
  padding: 8px;
  color: #ffffff;
}

.p-card .p-card-title {
  size: 20px !important;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #464A4F !important;
}

.p-dataview .p-dataview-content {
  background: none;
  color: #495057;
  border: 0 none;
  padding: 0;
}

.Timeline-Css {
  display: flex;
  text-align: end;

}

.Timeline-Css .p-timeline-event-opposite{
  max-width: 2px;
  padding: 0px;
}

.Timeline-Css .p-timeline-event-content{
  max-width: 200px;
  padding: .5rem .5rem !important;
  text-align: left;
}

.kanbam{
  /*max-width: 1360px;*/
  overflow-x: scroll;
  height: 75vh;
}

.Timeline-Css .p-card-content {
  color: black !important;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.5rem;
}

.Timeline-Css .p-timeline-event-separator span{
  display: flex;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  align-items: center;
  justify-content: center;
}

.Timeline-Css .p-card-title{
  font-weight: 700;
  font-size: 14px !important;
  font-weight: bold;
}

.Timeline-Css .p-card-subtitle{
  font-size: 14px !important;
}
.Timeline-Css .p-card-content{
  font-size: 14px !important;
}
