.ocultar {
  display: none;
}

.ocultar2 {
  display: block
}

.p-panelmenu .p-panelmenu-header > a {
  padding: 1.25rem;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

/*  add por felipe, sempre manter no codigo */

.group-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 1rem;
}

.second-dropdown{
  margin-top: 6.633px;
}

/* cusrsor poiter */

.pointer {cursor: pointer;}

.center-items {
  justify-content: center;
  align-items: center;
}
.align-center {
  align-items: center;
}
/* margin top e botton */
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.my-4 {
  margin: 4rem 0;
}

/* margin bottom */

.mb-1{
  margin-bottom: 1rem;
}


/*margin top*/

.mt-1-e {
  margin-top: 1rem;
}
/*espacamento interno*/
.gap-0{
  gap: .25rem;
}
.gap-1{
  gap: .75rem;
}
.row-etrium{
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
}

.multiselect-kanban-etrium {
  display: flex;
  flex: 2;
  max-width: 30%;
  height: 37px;
}

.flex-column{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    flex-direction:column;
    /* margin-right: -15px;
    margin-left: -15px; */
}

@media (max-width: 768px) {
  .row-etrium{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }
  .multiselect-kanban-etrium {
    display: flex;
    flex: 2;
    max-width: 100%;
    height: 37px;
  }
}


.p-tabview-panels {
  padding: 1.25rem 1.25rem 1.25rem 0 !important;
}
.grid-etrium {
  display: flex;
  flex-wrap: wrap;
  margin-right: -.5rem;
  margin-left: -.5rem;
  margin-top: -.5rem;
}
.mt-0 {
   margin-top: 0;
 }

.btnImprimir-position-absosute{
  position: absolute;
  right: 3rem;
  top: 3rem;
}

.text-decoration-underline{
  text-decoration: underline;
}




