.circulo_usuario {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(235, 229, 225);
  margin: 50px;
  }

.formGroupP {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  padding: 5px;
}