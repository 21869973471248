.fc-h-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  font-size: 12px;
}
.flex-1 {
  display: flex !important;
  flex:1;
}
.gap-1{
  gap: .75rem;
}
.status-etrium{
  padding-top: 4px;
}

.fc-toolbar-title::first-letter{
  text-transform: capitalize;
}