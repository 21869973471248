.direita {
  text-align: left;
  margin-top: 16px;
}

.row input{
  /*height: calc(2.25rem + 2px) !important;*/

}

.p-autocomplete .p-autocomplete-multiple-container {
  min-width: 58%;
  padding-top: 0 !important;
  height: 37px;
}

.p-dropdown-filter {
  width: 100%;
  height: 37px;
  font-size: 90%;
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}

.p-multiselect-filter-container .p-inputtext {
  width: 100%;
  height: 37px;
  font-size: 90%;
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}

.p-multiselect .p-multiselect-label {
  padding: 0.55rem 0.55rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.partes {
  color: rgb(94, 108, 233);
}

.hide{
  display: none !important;
}

.vermelho {
  color: red;
  font-weight: bold;
}

.verde {
  color: rgb(13, 139, 41);
  font-weight: bold;
}

.center{
  text-align: center !important;
}

.blue-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--blue-500);
}

.blue-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--blue-500);
}

.modal_loader_container {
  width: 100vw;
  height: 100vw;
  background: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: none;
  justify-content: center;
  align-items: center;
}

.modal_loader_container.mostrar {
  display: flex;
}

.modal_loader {
  opacity: 0.4;
  background: white;
  width: 30px;
  min-width: 170px;
  position: fixed;
  padding: 30px;
  top: 300px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.linkCadastroNovoCliente {
  color: #4F88FF;
  cursor: pointer;
}


.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #6c757d;
  background: #ffffff;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.p-accordion .p-accordion-content {
  padding: 0.25rem !important;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-accordion-header-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  text-decoration: none;
  background: #ffffff;
}

.totalProcessos {
  background-color: darkgreen;
}

.totalAtivos {
  background-color: rgb(103, 107, 103);
}

.totalExcluidos {
  background-color: crimson;
}

.totalArquivados {
  background-color: rgb(20, 163, 220);
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: rgb(255, 248, 248);
  /*font-size: 22px !important;*/
}
.valoresProcessos{
  font-size: 25px;
  color:rgb(255, 248, 248);
  text-align: center;
}

.cardProcessos {
  background-color: #23375e !important;
}

.cardTeste {
  border-radius: 8px;
  box-shadow: 7px 7px 13px 0 rgba(110, 108, 108, 0.897);
  padding: 30px;
  margin: 1px;
  /*width: 250px*/;
  height: 140px;
  flex: 1 1 150px;
  transition: all 0.3s ease-out;
  background-color: #485c85;
}

.blue {
  border-left: 3px solid #f0f2f5;
}

.titulo {
  font-size: 16px;
  flex: 1 1 140px;
}

.card p {
  color: #a3a5ae;
  font-size: 18px;
  flex: 1 1 150px;
}

.textoCard{
  color: #f9fbff;
}

.p-autocomplete .p-autocomplete-multiple-container {
  min-width: 100%;
  padding: 0 6px ;
  /*height: 32px;*/
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token{
  padding-top: 0 !important;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  display: flex;
  gap:2px;
  align-items: center;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #495057;
  padding-top: 2px;
  /*margin: 1rem;*/
}
.card-max-width{
  width: 100%;
  max-width: 670px;
  /*background-color: #CCCCCC;*/

}
.card-form-align-start{
  justify-content: start;
  align-items: start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.width-100 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.width-50{
  display: flex;
  flex-direction: column;
  width: 48.5%;
}
.flex-1 {
  flex:1 !important;
}
.gap-1{
  gap: .75rem;
}
.gap-5{
  gap: 1rem;
}
.gap-4{
  gap: 0.8rem;
}
.gap-3{
  gap: 0.5rem;
}

.group {
  flex: 1;
  flex-direction: column;
  margin-bottom: 1rem;
}

.footer-buttons{
  justify-content: start;
  align-items: start;
  margin-left: -7.5px !important;
}

.minus-top{
  margin-top: 0;
}

.card-processo{
  border-radius: 8px;
  box-shadow: 7px 7px 13px 0 rgba(110, 108, 108, 0.897);
  padding: 30px;
  margin: 1px;
  /*width: 250px*/;
  /*height: 140px;*/
  flex: 1 1 150px;
  transition: all 0.3s ease-out;
  background-color: #485c85;
}

.card-press:hover{
  scale: 1.2;
}
