

.report-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    text-align: left;
    display: grid !important;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-gap: 20px;
}
