  /* .tour-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);;
  } */
  
  .tour-content {
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: absolute; /* Mudança adicionada */
    transform: translate(-50%, -50%); /* Mudança adicionada */
  }
  

  .white-block {
    position: absolute;
    z-index: 10000;
    pointer-events: none;
  }

 
  .white-block::after {
    content: '';
    position: absolute;
    margin-top: -58px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 20px);
    height: calc(100% + 20px); 
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
    z-index: 10001;
    
  }
  
  .balloon {
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    z-index: 10000;
    display: flex;
    justify-content: center;
  }
  
  .balloon .content {
    background-color: #fff;
    font-size: 18px;
    color: rgb(0, 0, 0);
  }

  .close-btn {
    color: rgb(83, 83, 83);
    font-size: 15px;
    background-color: #6161611e;
    border-radius: 15px ;
    margin-right: 10px;
    margin-top: 10px;
  }

  .return-btn{
    font-size: 18px;
    text-decoration: underline;
    color: rgb(0, 0, 0);
  }
  
  .content {
    background-color: rgb(45, 84, 212);
    color: white;
    padding: 5px;
  }
  
  .button-container{
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }
  
  .tour-title {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
    display: block;
  }

  .div-title {
    display: flex;
    justify-content: space-between;
  }

  .divider {
    border: 0;
    height: 1px;
    background: #007bff;
    margin: 10px 0;
  }

  .prev-btn,
  .next-btn {
    border: none;
    cursor: pointer;
    margin-top: 12px;
    margin-right: 5px;
  }

  .end-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
  .prev-btn svg,
  .next-btn svg {
    fill: rgb(0, 0, 0);
  }
  