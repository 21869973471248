.despesa {
  background-color: crimson;
}

.receita {
  background-color: darkgreen;
}

.saldo {
  background-color: #676b67;
}

.amanha {
  background-color: #2f67b1;
}

.hoje {
  background-color: #602572;
}

.pendente {
  background-color: #972765;
}


.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: rgb(255, 248, 248);
}

.valores{
  font-size: 28px;
  color:rgb(255, 248, 248);
  text-align: center;
}

.cursor {
  cursor: pointer;
}

.obrigatorioAsterisco {
  color: rgb(218, 8, 8);
}

.p-datatable table {
  border: none;
  min-width: 100%;
  table-layout: fixed;
}

tr {
  border: 0;
}

.relatorio {
  font-weight: bolder;
}

.filtro {
  color: rgb(98, 145, 233);
}

.card-financeiro{
  border-radius: 8px;
  box-shadow: 7px 7px 13px 0 rgba(110, 108, 108, 0.897);
  padding: 10px 5px;
  text-align: center;
  margin: 1px;
  /*width: 250px*/;
  max-height: 90px;
  height: 90px;
  flex: 1 1 150px;
  transition: all 0.3s ease-out;
}

.cardDespesa {

  background-color: #e61717;
}

.cardReceita {

  background-color: #054b09;
}

.cardVencendoAmanha {

  background-color: #2f67b1;
}

.cardVencendoHoje {

  background-color: #602572;
}

.cardPendente {

  background-color: #972765;
}

.cardSaldo {
  background-color: #676b67;
}

.blue {
  border-left: 3px solid #f0f2f5;
}

.titulo {
  font-size: 16px;
  flex: 1 1 140px;
}

.card p {
  color: #ffffff;
  font-size: 14px;
  flex: 1 1 150px;
}

.textoCard{
  color: #f9fbff;
}

.card-financeiro:hover{
  scale: 1.2;
}

.row-hover:hover{
  background-color: #e2e2e2 !important;
}
