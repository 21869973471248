.naoLida {
  font-weight: bold;
  color: black;
}
.notify-count{
  font-size: .7rem;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 4px;
  position: absolute;
  right: 5px;
  top: 0;
  color: #fff;
  background-color: #dc3545;

}
