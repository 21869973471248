.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #dee2e6;
  border: none;
}
.flex-1 {
  flex:1 !important;
}
.gap-1{
  gap: .75rem;
}
.spaced{
  padding-right: .75rem;
}