.grid-row {
    display: grid;
    grid-template-columns: repeat(6, minmax(270px, 1fr));
    grid-gap: 10px;
}
.columns-cards{
    height: 65vh;
    overflow-y: scroll;
    /*max-height: 600px;*/
}
.columns-cards::-webkit-scrollbar {
    width: 5px !important;
}
.columns-cards::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #888;
}

.columns-cards::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
.text-font {
    font-size: 14px !important;

}
.font-bold {
    font-weight: bold;
}
.card-kanban {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin: 1rem;
    padding: 0.70rem;
    box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
}

/*@media (max-width: 768px) {*/
/*    .grid-row {*/
/*        grid-template-columns: repeat(1, 1fr);*/
/*    }*/
/*}*/
