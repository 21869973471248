.img-logo-home{
    /*float: left;*/
    /*line-height: .8;*/
    margin-left:4.6rem;
    margin-right: .5rem;
    max-height: 33px;
    width: auto;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

.text{
    margin-left: 0;
    margin-right: 4.6rem;
}

.user-size{
    /*padding-top: 5px;*/
    font-size: 1.3rem;
}
.nav span {
    color: #212529 !important;
    /*color: #3B82F6 !important;*/
}
.nav .nav-link {
    color: #212529 !important;
    /*color: #3B82F6 !important;*/
}

.nav span:hover {
    background-color: #3B82F6 !important;
    /*opacity: 0.5;*/
    color: white !important;

}
.nav li a:hover {
    background-color: #3B82F6 !important;
    /*opacity: 0.5;*/
    color: white !important;

}
.nav ul li:hover .nav-link:hover {
    background-color: #3B82F6 !important;
    /*opacity: 0.5;*/
    color: white !important;
}

@media (max-width: 768px) {
    .btn-link {
        font-size: 10px !important;
    }
}

.empresa-style{
    display: flex;
    flex: 1;
    gap: .55rem;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    padding: 8px 16px;
    margin-bottom: 10px;
    color: black !important;
    font-size: 16px;
}
/*#bbc6dd*/
