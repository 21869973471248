.obrigatorioAsterisco {
  color: rgb(218, 8, 8);
}


.p-dropdown-filter {
  width: 100%;
  font-size: 90%;
  padding-right: 1.75rem;
    margin-right: -1.75rem;
}


.kleiton {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.space-etrium{
  padding-left: 0.25rem;
}