.dropContainer{
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  width: 400px;
  justify-content: center;
  transition: height 0.2s ease;
}

.uploadMessage{
  display: flex;
  color: rgb(54, 102, 6);
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}