.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 700px;
}

.content {
  max-width: 700px;
  margin: 5px;
  background: #fff;
  border-radius: 4px;
}