
.container-cad {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.content-header-cad {
    padding: 15px 0.5rem;
    background-color: #fff;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.card-body-cad{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
}
.card-form-cad{
    width: 100%;
    max-width: 670px;
}


.col-colum-password-msg {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    color: red;
    font-size: 14px;
    flex-direction: column;
    margin: 0.3rem 0;

}
.footer-buttons-cadastro {
    width: 100%;
    display: flex;
    gap: 0.250rem;
    /* topo | direita | inferior | esquerda */
    /*x | y*/
    margin: 1rem 0;
}

.valid-password {
    margin: 0.125rem 0;
    color: green;
}

.invalid-password {
    color: red;
    margin: 0.125rem 0;
}

.group {
    flex: 1;
    flex-direction: column;
    margin-bottom: 1rem;
}
.password-group {
    flex: 1;
    flex-direction: column;
}

.row{
    /*margin: 0 0;*/
}

@media (min-height: 720px) {

    .container-cad {
        width: 100%;
        /*padding: 50px 0;*/
    }

    .content-header-cad {
        padding: 15px 0.5rem;
        background-color: #fff;
        width: 100%;
    }

}
